import './App.css';

function App() {

  const handleScroll = () => {
    const previewElement = document.getElementById('more-info');
    if (previewElement) {
      previewElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <div className="App">
      <div className="site-title"><span>P</span><span>re</span><span>M</span><span>is</span></div>
      <div className="site-heading">Preventing Misinformation</div>
      <div className="site-blurb">Connecting you with the knowledge of researchers around the world</div>
      <img className='hero-img' src={require('./heroImg.png')} alt='Hero' />
      <button id="link" onClick={handleScroll} className='learn-more'>Learn More</button>
      <svg className="bg" id="ew73SmHMDGp1" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 480" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
        <defs>
          <linearGradient id="ew73SmHMDGp4-fill" x1="0.086453" y1="0.451944" x2="0.955437"
            y2="0.774201" spreadMethod="pad" gradientUnits="objectBoundingBox"
            gradientTransform="translate(0 0)">
            <stop id="ew73SmHMDGp4-fill-0" offset="66.3355%" stopColor="#234657" />
            <stop id="ew73SmHMDGp4-fill-1" offset="100%" stopColor="#66767e" />
          </linearGradient>
          <linearGradient id="ew73SmHMDGp4-stroke" x1="0.086576" y1="0.456076" x2="0.95676"
            y2="0.789485" spreadMethod="pad" gradientUnits="objectBoundingBox"
            gradientTransform="translate(0 0)">
            <stop id="ew73SmHMDGp4-stroke-0" offset="65.9068%" stopColor="#234657" />
            <stop id="ew73SmHMDGp4-stroke-1" offset="100%" stopColor="#66767e" />
          </linearGradient>
        </defs>
        <g>
          <polygon
            points="0,-64 59.834357,-22.570776 44.099973,28.973534 -37.580259,49.909328 -60.867617,-19.777088 0,-64"
            transform="matrix(8.126669 0 0 3.473992 212.374251 119.442618)" fill="#dcdddd"
            stroke="#dcdddd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
          <polygon
            points="0,-64 60.867617,-19.777088 39.525975,37.433924 -37.487399,48.382662 -60.867617,-19.777088 0,-64"
            transform="matrix(8.126669 0 0 3.473992 208.17577 94.704835)"
            fill="url(#ew73SmHMDGp4-fill)" stroke="url(#ew73SmHMDGp4-stroke)" strokeWidth="8"
            strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
      <div className='more-info' id="more-info">
        <img className="knowledge-web" src={require('./knowledge-web.png')} alt="Web of Knowledge" />
        <div className="web-text">
          <div className='web-title'>
            Bridging the Gap
          </div>
          <div className='web-para'>
            <span style={{ color: "#09A3F4", fontSize: "1.5vw" }}>PREMIS will</span> provide a platform for researchers to write about their areas of expertise and convey research in a simplified format. By reading accurate information directly aimed at them, users will avoid the typical misinformation that occurs due to third parties on social media and media outlets. We hope to create a hub of knowledge-translated research to educate users worldwide and <span style={{ color: "#09A3F4", fontSize: "1.5vw" }}>prevent the spread of misinformation.</span>
          </div>
        </div>
      </div>
      <div className='email-notifications'>
        <div className="sign-up-text">
          Sign Up to Receive Updates on Development
        </div>
        <input type="email" placeholder='Email Address' className='email-input' id="email-input" />
        <button id="sign-up-btn" className="sign-up" onClick={() => {
          if (document.getElementById("email-input").value === "") {
            document.getElementById("sign-up-btn").innerHTML = "Please Enter an Email"
          } else if (!validateEmail(document.getElementById("email-input").value)) {
            document.getElementById("sign-up-btn").innerHTML = "Please Enter a Valid Email"
          }
          else {
            document.getElementById("sign-up-btn").innerHTML = "Thanks!"
            document.getElementById("email-input").value = ""
          }
        }}>
          Sign Up
        </button>
      </div>
      <div className='footer'>
        <div className='footer-text'>
          © 2023 Premis
        </div>
      </div>
    </div>
  );
}

export default App;
